const ENVIRONMENT = {
  DEV: 'dev',
  PROD: 'prod',
  INTERNALDEV:'internalDev'
};

let backendServices = "";
let realm = ""

switch (process.env.REACT_APP_ENV){
  case ENVIRONMENT.PROD:
    backendServices="https://aurora.cnsspa.it";
    realm="production"
    break;
  case ENVIRONMENT.INTERNALDEV:
    backendServices="http://192.168.128.227";
    realm="development"
    break;
  default:
    backendServices = "";
    realm="demo"
    break;
}
const keycloackService = "/auth/realms/"+realm+"/protocol/openid-connect/token";

export default {
  backendServices,
  "loginEndpoint": backendServices+keycloackService,
  "usersEndpoint": backendServices+"/api/v1/user",
  "customersEndpoint": backendServices+"/api/v1/customer",
  "siteEndpoint": backendServices+"/api/v1/site",
  "serviceEndpoint": backendServices+"/api/v1/service",
  "siteServiceEndpoint": backendServices+"/api/v1/site-service",
  "activityServiceEndpoint": backendServices+"/api/v1/activity",
}
