import decode from 'jwt-decode';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import Endpoints from '../Endpoints.js';
import qs from 'qs';
import {get} from 'lodash'
import {USER_ROLES} from "../types/backend/user.types";

interface AuthToken {
    realm_access: {
        roles: string[];
    };
    exp: number;
}

interface User {
    refresh_token: string;
}

export default class AuthService {
    domain: string;
    private static instance: AuthService | null;
    private user: User | undefined;

    // Initializing important variables
    constructor(domain?: string) {
        this.domain = domain || Endpoints.backendServices

        this.fetch = this.fetch.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.setToken = this.setToken.bind(this)
        this.login = this.login.bind(this)

        // @ts-ignore
        axios.defaults.crossDomain = true;
        axios.defaults.withCredentials = true;
        axios.defaults.timeout = 60000; // 60 secondi
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.put['Content-Type'] = 'application/json';

        axios.interceptors.response.use(undefined, error => {
          let res = error.response;
          const status = error.response ? error.response.status : null

          if (status === 401) {

            return this.refreshToken().then(res => {
              error.config.headers['Authorization'] = 'Bearer ' + res.data.access_token;
              error.config.baseURL = undefined;
              return axios.request(error.config);
            }).catch(error => {
              this.logout();
              // @ts-ignore
              window.history.go('/login');
            });
          }
          return Promise.resolve({...res, _error:error});
        });
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthService();
            return this.instance;
        } else {
            return this.instance;
        }
    }

    refreshToken = () => {
        const { refresh_token } = this.getProfile();

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':'Bearer ' + this.getToken(),
            }
        }

        const body = qs.stringify({
            client_id: "aurora-backend",
            grant_type: "refresh_token",
            refresh_token,
        })

        return axios.post(Endpoints.loginEndpoint, body, axiosConfig).then(res => {
          const token = get(res, 'data.access_token', undefined);
          if(token===undefined){
            console.warn("TOKEN NON VALIDO ... rimando a logout")
            return Promise.reject();
          }
          else{
            console.warn(`ho salvato il token rinnovato in localstorage... : ${res.data.access_token}`)
            localStorage.setItem('access_token', res.data.access_token);
            this.setUserProfile(res.data);
            return res;
          }

        })
    }

  checkUserIsAdmin(token: string){
    let decoded = decode<AuthToken>(token);
    return decoded.realm_access.roles.includes("admin")

  }

  checkUserCanAccessDashboard(token: string){
    let decoded = decode<AuthToken>(token);
    console.log(`checkUserCanAccessDashboard - decoded : ${JSON.stringify(decoded)}`)
    return decoded.realm_access.roles.includes("app-admin") ||  decoded.realm_access.roles.includes("app-backoffice")
  }

  getUserRoles(){
      const accessToken = localStorage.getItem('access_token') || "";
      try{
        const decoded = decode<AuthToken>(accessToken)
        return decoded.realm_access.roles;
      }
      catch (e){
        return "";
      }

  }




  isUserAdmin(){
      let userRoles = this.getUserRoles();
      return userRoles.includes(USER_ROLES.admin)
  }

  isUserBackOfficeOperator(){
    let userRoles = this.getUserRoles();
    return userRoles.includes(USER_ROLES.backoffice)
  }

  isUserWriter(){
    return false;
  }

  isUserSponsor(){
    return false;
  }



    login(email: string, pwd_hash: string) {
        let body=qs.stringify({
            client_id:"aurora-backend",
            grant_type:"password",
            username:email,
            password:pwd_hash
        });

        return this.fetch(`${Endpoints.loginEndpoint}`, body)
            .then(function(res){
                if(res && res.data) {
                  if(AuthService.getInstance().checkUserCanAccessDashboard(res.data.access_token)){
                    localStorage.setItem('access_token', res.data.access_token)
                  }else{
                    let err={data:{
                        error_description: "Credenziali non valide."
                      }}
                    return Promise.reject(err);
                  }
                }
                return Promise.resolve(res.data);
            }).catch((error) => {
                console.error(error)
                return Promise.reject(error);
            /*
            let userData = {
              "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI2UXlPUUZsMVF6aGlweVotbHRmRl9DNkllUlREaHZMTlF6eXQwOXNIT1gwIn0.eyJqdGkiOiJhYWQwODE4MC04MzBmLTQ0OTUtYWU3ZC0yZTAzM2VjNTE3YmIiLCJleHAiOjE1Njk4NTM3MzcsIm5iZiI6MCwiaWF0IjoxNTY4ODE2OTM3LCJpc3MiOiJodHRwOi8vMTkyLjE2OC4xLjIxMDoxMDAwMC9hdXRoL3JlYWxtcy90YWxrLXJlYWxtIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImUyNWI1YTgyLWRiZTMtNGMyNy04OTc2LTc5NGY0YzI2OTkyYSIsInR5cCI6IkJlYXJlciIsImF6cCI6InRhbGsiLCJhdXRoX3RpbWUiOjAsInNlc3Npb25fc3RhdGUiOiIwZjU3YWUzNi0wMzQ3LTQ3YWYtOWE2ZC0zMTQ0OGYzZWJiMDYiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiYWRtaW4iLCJ1bWFfYXV0aG9yaXphdGlvbiIsInVzZXIiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6Ik5pY29sw7IgU3RyYWRpb3R0byIsInByZWZlcnJlZF91c2VybmFtZSI6InRhbGstYWRtaW4iLCJnaXZlbl9uYW1lIjoiTmljb2zDsiIsImZhbWlseV9uYW1lIjoiU3RyYWRpb3R0byIsImVtYWlsIjoic3ZpbHVwcG9Ac291dGhlbmdpbmVlcmluZy5pdCJ9.JOJMRU1bnn9cDFyXGH-rnDSG5G-n8o39uk6XORSHUx4wK9BlYMEZZ-lOJ6JSnFJFiPDtTReOp63es9lWNxKS-JOCRbWuGPBjNj2Ga6azcnwRhQojUYB2UhpoQO3BqAz8ejL0UAZZYTo9DaQaFL2fpenGKIhdPVDlqyBzzB4O4VrLZ5szqNiXpvZBNrnZo9y5UNKIBRRLemP96L0WGavbw03piTeR28Xg1lLZHsBEeyJcuGyyWpj2pf-89jocx1zyFfPHtcUGnQuaYQykdmuMulbcJpv7ya9oL3UArHjPXCkla7yJ_dc_qZhjq8VQFD8LB8KTs-EJE0YhQSqnaP8R_A",
              "expires_in": 1036800,
              "refresh_expires_in": 31536000,
              "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIzYTVlMzFkZS1kYmVkLTQwYzQtYTRkOC1kZTZkZDc2MmEzYjcifQ.eyJqdGkiOiJhNTkyNWU5OC1mOTg5LTRjNTEtOWQ4Ny1mYWNiZTUxMmE3NWEiLCJleHAiOjE2MDAzNTI5MzcsIm5iZiI6MCwiaWF0IjoxNTY4ODE2OTM3LCJpc3MiOiJodHRwOi8vMTkyLjE2OC4xLjIxMDoxMDAwMC9hdXRoL3JlYWxtcy90YWxrLXJlYWxtIiwiYXVkIjoiaHR0cDovLzE5Mi4xNjguMS4yMTA6MTAwMDAvYXV0aC9yZWFsbXMvdGFsay1yZWFsbSIsInN1YiI6ImUyNWI1YTgyLWRiZTMtNGMyNy04OTc2LTc5NGY0YzI2OTkyYSIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJ0YWxrIiwiYXV0aF90aW1lIjowLCJzZXNzaW9uX3N0YXRlIjoiMGY1N2FlMzYtMDM0Ny00N2FmLTlhNmQtMzE0NDhmM2ViYjA2IiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiYWRtaW4iLCJ1bWFfYXV0aG9yaXphdGlvbiIsInVzZXIiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwifQ.U1egxV8oNkYStDYqJw221esF7PkSdeL0-qY_Wm3PPyA",
              "token_type": "bearer",
              "not-before-policy": 0,
              "session_state": "0f57ae36-0347-47af-9a6d-31448f3ebb06",
              "scope": "profile email"
            }
            localStorage.setItem('access_token', userData.access_token)
            this.setUserProfile(userData)
            */


            })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token // handwaiving here
    }

    isTokenExpired(token: string) {
        try {
            const decoded = decode<AuthToken>(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken: string) {
        // Saves user token to localStorage
        localStorage.setItem('access_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('access_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.clear();
    }

    getProfile(): User {
        if(this.user===undefined){
            // @ts-ignore
            this.user=JSON.parse(localStorage.getItem('user'));
        }
        return this.user as User;
        //return decode(this.getToken());
    }

    setUserProfile(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
        this.user=user;
    }


    getLoggedInHeaders(){
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + this.getToken()
        }
    }

    getLoggedInHeadersMultipar(){
        return {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization':'Bearer ' + this.getToken()
        }
    }

  getLoggedInHeadersFormUrlEncoded(){
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization':'Bearer ' + this.getToken()
    }
  }



    fetch(url: string, body: any) {
        // performs api calls sending the required authentication headers
        const config: AxiosRequestConfig ={
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            config.headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return axios.post(url, body,
            config);
    }

    _checkStatus<T>(response: AxiosResponse<T>): AxiosResponse<T> {

        // raises an error in case response status is not a success
        if (response && response.status  && response.status === 200) { // Success status lies between 200 to 300
            return response;
        }
        /*else if(response.data.result.code!=0){
            var error = new GeneralError()
            error.message = response.data.result.message
            error.code = response.data.result.code
            error.type = response.data.result.type
            error.msgToShow="Si è verificato un errore"
            throw error
        }*/
        else {
          let error = new Error(response.statusText)
          //@ts-ignore
          error.response = response
          //@ts-ignore
          error.status = response.status
          console.log(`_checkStatus - error: ${error}`)
          throw error
        }
    }
}
