import { MakeObjectTuple } from "../utility.types";

export interface KeyCloakAttributesExtracted extends VerificationState {
  cittaResidenza: string;
  titolo: string;
  dataNascita: string;
  iscrizioneOrdine: string;
  provinciaNascita: string;
  telefono: string;
  codiceFiscale: string;
  provinciaIscrizioneOrdine: string;
  specializzazione: string;
}

export type KeyCloakAttributes = MakeObjectTuple<KeyCloakAttributesExtracted>;

export interface VerificationState {
  userState: string;
  validationMessage: string;
}

export enum USER_ROLES {
  admin="app-admin",  backoffice="app-backoffice", operator="app-operatore"
}

export interface UserAddress {
  address:string,
  cap:string,
  city:string,
  number:string
}

export interface User {
  id: string;
  email: string;
  enabled: boolean,
  username: string,
  name: string,
  surname:string,
  phone:string,
  birthday:Date,
  address: UserAddress
  roles: USER_ROLES[];
  attributes: KeyCloakAttributes;
  terms:boolean;
  conditions:boolean;
  password?:string

}

